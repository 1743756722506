<template>

      <v-tab-item
          value="data"
          class="fill-height"
      >
        <v-card flat class="fill-height d-flex flex-column pt-4">     
          <p class="text-body2 panelTabHeader pl-2 pb-2" color="">DATA VIEW</p>
          <div style="width: 100%; height: 85px;" class="pt-2">
               <v-row class="mx-1 mb-2" style="border-radius: 11px; border: solid 1px #893FF2; height:40px;">
                <v-col cols="5" class="pa-2 pl-1">
                  <dualMonth :disableTime="false" 
                  @changedDate='newDate'>
                  </dualMonth>
                </v-col>
                <v-col cols="6" class="pa-2 searchBoxPosition" style="border-left: solid 2px #C4C4C4; border-radius: 12px; height: 40px;">
                  <searchBox @aoiSelected="newAOI"></searchBox>
                </v-col>
              </v-row>
            <!-- <v-layout class="px-3" style="height: 50px">
              <div style="width: 45%" class="mr-2">
                <month-picker  @input="() => $emit('changeMonth', this.month.from, this.month.to)" :label="'From month'" :max.sync="month.to" v-model="month.from"/>
              </div>
              <div style="width: 45%" class="ml-2">
                <month-picker @input="() => $emit('changeMonth', this.month.from, this.month.to)" :label="'To month'" :min.sync="month.from" v-model="month.to"/>
              </div>
            </v-layout> -->
           <v-layout style="height: 30px" class="px-3 justify-space-between" align-center>
             <p class="text-center text-body2 align-self-center my-0" style="color: #893ff2;">Group By:</p>
              <div style="width: 45%" class="ml-2">
                <v-card width="180" height="30">
                  <v-layout justify-center align-center class="fill-height">
                    <v-btn-toggle
                        mandatory
                        v-model="mode"
                        tile
                        color="deep-purple accent-3"
                        group
                    >
                      <v-btn
                          :style="{'border': mode === 'type'? '1px solid #893FF2' : '', 'border-radius': mode === 'type'? '5px' : '0'}"
                          value="type"

                          x-small>
                        Data type
                      </v-btn>
                      <v-btn
                          :style="{'border': mode === 'month'? '1px solid #893FF2' : '', 'border-radius': mode === 'month'? '5px' : '0'}"
                          style="border-radius: 3px"
                          value="month"
                          x-small>
                        Month
                      </v-btn>
                    </v-btn-toggle>
                  </v-layout>
                </v-card>
              </div>
            </v-layout>
          </div>
          <v-layout column class="overflow-y-auto pb-3 mr-2" style="height: 100%">
            <v-expansion-panels multiple flat style="height:455px;" class="s-expansionPanel">
              <v-expansion-panel v-for="(group, idx) of groups" :key="idx">
                <v-expansion-panel-header>
                  <span style="text-transform: uppercase; font-weight: bold;"><v-icon class="mr-3">mdi-folder-outline</v-icon>{{group.name}}</span>
                  <v-spacer/>
                  <span class="mr-2" style="text-align: end; font-size: 12px;">(Total: {{group.images.length}} image)</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list dense>
                    <v-list-item v-for="(item, index) in group.images" class="elevation-1 my-2 pl-0" :key="index" style="border: solid 1px black" :style="{borderLeft: 'solid 5px ' + item.color + ''}" @click="itemclick(item)">
                      <!-- <span>{{item}}</span> -->
                      <v-layout column >
                        <v-layout style="height: 35px" align-center>
                          <!-- <div style="border-bottom-left-radius: 5px; border-top-left-radius: 5px; height: 40px; width: 5px;" :style="{'background-color': item.color}" class="mr-3"></div> -->
                          <v-layout class="fill-height" align-center>
                            <span style="font-size: 13px; margin-left: 15px;">{{item.name}}</span>
                            <v-spacer/>
                            <FullScreenSquare :color="item.color"/>
                          </v-layout>
                        </v-layout>
                      </v-layout>
                    </v-list-item> 
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-layout>
        </v-card>
      </v-tab-item>

</template>

<script>
import FullScreenSquare from "@/components/imagery/FullScreenSquare";
import {mapState} from "@/store/ults";
import Store from "@/store";
import MonthPicker from "@/components/MonthPicker";
import dualMonth from "@/components/dualMonthRangePicker";
import search from "@/components/home/aoiMenu";
export default {
  name: "Mosaic",
  components: {MonthPicker, FullScreenSquare, dualMonth, 
    'searchBox' : search
  },
  data () {
    return {
      tabs: 'data',
      mode: 'type',
      monthSelection: require('@/assets/monthSelect.json'),
      month: {
        from: undefined,
        to: undefined
      },
      pickertype: 'month',
      titleformat : 'YYYY-MM-DD',
      dateformat:"YYYY-MM-DD",
      aoi: ''
    }
  },
  props: {
    fromMonth: {
      type: String
    },
    toMonth: {
      type: String
    },
    groups: {type: Array, default: () => []},
    areas: {type: Array, default: () => []}
  },
  watch: {
    mode (val) {
      this.$emit('input', val)
    },
    groups (val) {
      console.log('val: ', val);
      if (!this.currentPoint) return
      this.groups.forEach(group => {
        let currentImage = group.images.find(image => image.id === this.currentPoint.image.id)
        if (currentImage) this.$emit('changeListLayer', currentImage)
      })
    }
  },
  computed: {
    ...mapState("point", ["currentPoint"]),
  },
  mounted() {
    this.month.from = this.fromMonth
    this.month.to = this.toMonth
  },
  methods: {
      itemclick(i) {
          i.id = i.id +"_"+ i.AoiName
        // Emmitted when layer results are clicked
        this.$emit('zoomToImage', i)
        this.$emit('changeListLayer', i)
        // console.log('Data Clicked: ', i);
      },
        newDate(date){

          this.month.from = date[0]
          this.month.to = date[1]
          // console.log(this.month)

          this.$emit('changeMonth', this.month)
          // var test = 
          // console.log(date)
        // this.date.from = date[0].split("-").reverse().join("-")
        // this.date.to = date[1].split("-").reverse().join("-")
      },
      newAOI(x){
        console.log('new AOI',x)
        this.aoi = x;
			   let tempId = x.name.split(' ')
         tempId = parseInt(tempId[1])
         this.aoi.id = tempId
		    this.$emit('changeAOI', this.aoi)
var i = 
{
                "id": this.aoi.name,
                "name": this.aoi.name,
				"AoiName" :" ",
                "list_values": [],
				"geometry" : this.aoi.geometry,
                "bbox": this.aoi.bbox,
                "type": "AOI",
                "data_type": "vector",
                "selected": true,
				"layoutRight": true,
                "opacity": 1,
                "layoutLeft": true,
                "displayLabel": true,
                "map_type": "vector",
                "color": "#FF0000"
            }
			
  this.$emit('zoomToImage', i)
this.$emit('changeListLayer', i)
        // console.log('New AOI: ' ,this.aoi)
        // this.changeAOI();
      }
  }
}
</script>

<style scoped>
/deep/
.sidebar-tab .v-tabs-bar {
  height: 45px !important;
}
/deep/
.small-radio .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
  background: currentColor;
  transform: scale(0.9);
  transition: none;
}

.s-expansionPanel {
  display: block !important;
}

.panelResultContainer{
  border-bottom: 1px solid rgba(137, 63, 242, 0.2);
  border-right: 1px solid rgba(137, 63, 242, 0.2);
  border-left: 1px solid rgba(137, 63, 242, 0.2);
  border-radius: 9px;
}

/* Reduce gap at the bottom of the panel exppanded */
.v-expansion-panel-content__wrap{
  padding: 0px 24px 6px !important;
}

/* Makes Button smaller */
.v-expansion-panel-header{
  padding: 5px 24px;
}

/* Remove the excessive 16px at the top of panels */
.v-expansion-panel--active:not(:first-child), .v-expansion-panel--active + .v-expansion-panel{
  margin-top: 4px !important;
}

button.v-btn.v-item--active.v-btn--active.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default{
  background-color: white !important;
  color: #893ff2;
  border: none !important;
}
</style>
