<template>
  <div class="table-wrapper">
    <h3 id="left_label" style="background-color:white;position:absolute;top:60px;right:50%;">LEFT PERIOD</h3>
	<h3 id="right_label" style="background-color:white;position:absolute;top:60px;left:50.2%;">RIGHT PERIOD</h3>
  <br>
  <v-row style="margin-left:20px;" >
  
          <h3 style="color: #893FF2;">DATA LIBRARY CHANGE</h3>
	
   
		  <span style="margin-left:20px;font-weight: 700;">View Changes between</span>
                  <div style="width: 300px;" class="pl-3">
                    <v-autocomplete
                        flat
                        style="border:1px"
                        @change="changeYYMM"
                        v-model="yymm_item"
                        :items="yymm_lst"
                       
                        dense
						color="grey"
                       
                        item-text="yymm_label"
                        return-object/>
                  </div>
  
                   <span style="margin-left:20px;font-weight: 700;">AOI</span>
                  <div style="width: 300px;" class="pl-3">
                    <v-autocomplete
                        flat
                        style="border:1px"
                        @change="changeAOI"
                        v-model="aoi"
                        :items="aois"
                       
                        dense
						color="grey"
                       
                        item-text="properties.NAME"
                        return-object/>
                  </div>
				  
	            <span style="margin-left:20px;font-weight: 700;">Change Type</span>
                  <div style="width: 200px;" class="pl-3">
                    <v-autocomplete
                        flat
                        style="border:1px"
                        @change="changeTyp"
                        v-model="typ_item"
                        :items="typ_lst"
                       
                        dense
						color="grey"
                       
                        item-text="label"
                        return-object/>
                  </div>			  
				  
				  
 <v-btn @click="hideChangeDetection" outlined style="margin-left: 20px; background-color: white;" color="#893FF2" height="30" width="190" min-width="0">
      <span style="">
        <v-icon color="#893FF2">mdi-chevron-double-down</v-icon>
		Change Detection
      </span>
    </v-btn>				  
				  
	</v-row>

    <div class="table-content">
	
 <v-client-table :data="detections" :columns="columns" :options="options" @select="select_row" >
  
    <!--
  <i slot="preview" slot-scope="props" aria-hidden="true" @click="zoom(props.row)" class="v-icon notranslate mdi mdi-crosshairs-gps theme--light"></i>
	-->
	<div slot="preview" slot-scope="props">
 <input type="checkbox" :id="props.row.id" @change="preview_chg(props.row)">
  <span :id="'span-' + props.row.id"  style="margin-left:10px"  @click="span_preview_click(props.row)">Preview</span>
 </div>
 
</v-client-table>

    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import {mapState} from "@/store/ults";
import { cloneDeep } from 'lodash'
import EventBus from '@/event-bus'
import {ClientTable, Event} from 'vue-tables-2-premium'
import aois_geojson from '@/assets/timah0.json'
import MonthPicker from "@/components/MonthPicker"
import {getDetections_Count, getDetections} from "@/backend"
import {getDetectionsx_Count, getDetectionsx} from "@/backend"
import area from'@turf/area'
import * as turf from '@turf/turf'

Vue.use(ClientTable)

export default {
 components: {
 MonthPicker,
 ClientTable
  },
  props: {
    tableData: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
	last_preview_id : undefined,
	preview_mosaic1_id :'',
	preview_mosaic2_id :'',
	
	 mmm_lst :  ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  
 yymm_lst : [{yymm:'2020-02'},{yymm:'2020-03'},{yymm:'2020-04'},{yymm:'2020-05'},{yymm:'2020-06'},{yymm:'2020-07'},{yymm:'2020-08'},{yymm:'2020-09'},{yymm:'2020-10'},{yymm:'2020-11'}],
 typ_lst : [{typ: 'gn', label : 'Green To Non Green'} , {typ: 'ng', label : 'Non Green To Green'}],
 typ_item : {typ: 'gn', label : 'Green To Non Green'},
	loading: false,
		yymm_item : {},
		detections : [],
	    yymm : '',
		aois_hash : {},
		aois : [],
		aoi : {},
  columns: ['preview','aoi','prov', 'mine' , 'aoi_area','area','pchg','id1'],
   
    options: {
      headings: {
        id1 : 'Change ID',
		aoi : 'AOI NAME',
		prov : 'Province',
		mine : 'Mine Type',
		aoi_area : 'AOI Area (ha)',
        area : 'Change Area (ha)',
		pchg :'% of area change'

      },

     // editableColumns:['name'],
      sortable: ['aoi','prov', 'mine' , 'aoi_area','area','pchg','id1'],
      //filterable: ['name', 'code']
	   selectable:{
    mode: 'single', // or 'multiple'
    only: function(row) {
       return true // any condition
    },
    selectAllMode: 'all', // or 'page'
    programmatic: false,

  }
    },

      filterWord: '',
 

    }
  },
mounted() {

    
       EventBus.$on('featureSel', (id) => {
      this.onFeatureSel(id)
    })
	
	      EventBus.$on('featureUnSel', (id) => {
      this.onFeatureUnSel(id)
    })
 
 this.yymm_lst.reverse()
 
 this.yymm_lst.forEach((m) => {
 
 m.yymm_label = this.period_mmmyy(m.yymm)
 
 })
 
 console.log(this.yymm_lst)
 
  aois_geojson.features.forEach((f) => {
  
var x1 = 999999999
var x2 = -999999999
var y1 = 999999999
var y2 = -999999999
var c = f.geometry.coordinates[0]

for (var a = 0; a < c.length; a++ ) {
	
	if (c[a][0] < x1)
		x1 = c[a][0]
	if (c[a][0] > x2)
		x2 = c[a][0]	
	if (c[a][1] < y1)
		y1 = c[a][1]
	if (c[a][1] > y2)
		y2 = c[a][1]		
}
  
  f.bbox =  [
                    x1,
                    y1,
                    x2,
                    y2
                ]
  
  })
    this.aois = aois_geojson.features
	
this.aois.forEach((f) => {

var polygon = turf.polygon(f.geometry.coordinates)

f.area = area(polygon)/10000.0

//console.log(f.area)

this.aois_hash[f.properties.NAME] = f
})

window.left_label = document.getElementById('left_label')
window.right_label = document.getElementById('right_label')

//setTimeout(this.setvalues,500)
 
},
  computed: {
  },
  watch: {
    tableData: {
      immediate: true,
      handler (val, oldVal) {
        this.localData = cloneDeep(val)
		//console.log(this.localData)
      }
    }
  },
  beforeMount() {
    this.gridOptions = {
      getRowNodeId: function(data) {
        return data.id
      }
	  }
  },
  methods: {
  preview_chg(e){
  
  if (document.getElementById(this.last_preview_id))
  {
  var chk0 = document.getElementById(this.last_preview_id)
  
  chk0.disabled = false
  chk0.checked = false
  var span0 = document.getElementById('span-' + this.last_preview_id)
   span0.style.cursor = "default"
  span0.textContent = 'Preview'
  }
  
  var chk = document.getElementById(e.id)
  var span = document.getElementById('span-' + e.id)
  if (chk.checked) {
  this.last_preview_id = e.id
  chk.disabled = true
  e.preview = 'Load Preview'
  span.style.cursor = "pointer"
  span.textContent = 'Load Preview'
   this.zoom(e)
  
  }
  },
  span_preview_click(e) {
 
 var span = document.getElementById('span-' + e.id)
 
 if (span.textContent == 'Load Preview')
 {
	this.select_row(e)
	 span.style.cursor = "default"
  span.textContent = 'Loaded'
  this.last_preview_id = ''
 }
 
  },  
async  setvalues(){
  this.yymm_item = {yymm:'2020-08',yymm_label : this.period_mmmyy('2020-08')}
this.aoi = this.aois_hash['1533']
this.changeYYMM()
  },
  period_mmmyy(yymm) {
  
  return this.mmmyy(this.last_yymm(yymm)) + ' TO ' + this.mmmyy(yymm)
	
  },
  mmmyy(yymm) {
 var lst = yymm.split('-')
var y = lst[0]
var m = Number(lst[1]) 
return this.mmm_lst[m - 1].toUpperCase() + ' ' + y
  },
  hideChangeDetection() {
  this.$emit('hideChangeDetection')
  },
 select_row(e) {
 
// e = e[0]
 
 var lst = e.ullr.split(',')

var bbox = [parseFloat(lst[0]),parseFloat(lst[1]),parseFloat(lst[2]),parseFloat(lst[3])]

  
this.$emit('zoomToImage', {bbox:[parseFloat(lst[0]),parseFloat(lst[1]),parseFloat(lst[2]),parseFloat(lst[3])]})


window.wms(this.yymm  + '_' + e.aoi + '_Change_' + e.id,'selected',"qa_id=" + e.id,bbox )

  this.$emit('zoomToImage', {bbox:bbox})

	var tile_url1 = 'http://23.106.120.91:8005/tileserver.php?/index.json?/' + this.last_yymm(this.yymm) + '@' + e.aoi + '/{z}/{x}/{y}.png'
	var tile_url2 = 'http://23.106.120.91:8005/tileserver.php?/index.json?/' + this.yymm + '@' + e.aoi + '/{z}/{x}/{y}.png'
	
	var id1 = this.last_yymm(this.yymm) + '_' + e.aoi + '_Mosaic'
	var id2 = this.yymm + '_' + e.aoi + '_Mosaic'

	var bbox = this.aois_hash[e.aoi].bbox
	
   const img1 = {
    "id": id1,
    "tile_url": tile_url1,
    "bbox": bbox,
    "min_zoom": 1,
    "max_zoom": 18,
    "type": "mosaic",
    "name": id1,
    "layoutRight": false,
    "layoutLeft": true,
	                "data_type": "raster_image",
                "selected": true,
                "opacity": 1,
                 "displayLabel": true,
                "map_type": "raster_image",
			"color": "#00DDDD"
  }

   const img2 = {
    "id": id2,
    "tile_url": tile_url2,
    "bbox": bbox,
    "min_zoom": 1,
    "max_zoom": 18,
    "type": "mosaic",
    "name": id2,
    "layoutRight": true,
    "layoutLeft": false,
	                "data_type": "raster_image",
                "selected": true,
                "opacity": 1,
                 "displayLabel": true,
                "map_type": "raster_image",
				 "color": "#00DDDD"
  }
  
  this.clear_preview_mosaic()
  
  this.$emit('changeListLayer', img1)
   this.$emit('changeListLayer', img2)  
   
   var id = e.aoi
   
   var aoi = this.aois_hash[e.aoi]

var i = 
{
                "id": id,
                "name": id,
 
                "list_values": [],
				"geometry" :  {
"type": "FeatureCollection",
"features": [aoi]
},
                "bbox": aoi.bbox,
                "type": "AOI",
                "data_type": "vector",
                "selected": true,
				"layoutRight": true,
                "opacity": 1,
                "layoutLeft": true,
                "displayLabel": true,
                "map_type": "vector",
                "color": "#FF0000"
            }
			
 // this.$emit('zoomToImage', i)
this.$emit('changeListLayer', i)
  
  },  
 zoom(e) {
 
  var lst = e.ullr.split(',')
  
  this.$emit('zoomToImage', {bbox:[parseFloat(lst[0]),parseFloat(lst[1]),parseFloat(lst[2]),parseFloat(lst[3])]})
  
  
  	var tile_url1 = 'http://23.106.120.91:8005/tileserver.php?/index.json?/' + this.last_yymm(this.yymm) + '@' + e.aoi + '/{z}/{x}/{y}.png'
	var tile_url2 = 'http://23.106.120.91:8005/tileserver.php?/index.json?/' + this.yymm + '@' + e.aoi + '/{z}/{x}/{y}.png'
	
	//var id1 = this.last_yymm(this.yymm) + '_' + e.aoi + '_Preview'
	//var id2 = this.yymm + '_' + e.aoi + '_Preview'

	var id1 = 'Preview1'
	var id2 = 'Preview2'

	var bbox = this.aois_hash[e.aoi].bbox
	
   const img1 = {
    "id": id1,
    "tile_url": tile_url1,
    "bbox": bbox,
    "min_zoom": 1,
    "max_zoom": 18,
    "type": "mosaic",
    "name": id1,
    "layoutRight": false,
    "layoutLeft": true,
	                "data_type": "raster_image",
                "selected": true,
                "opacity": 1,
                 "displayLabel": true,
                "map_type": "raster_image",
			"color": "#00DDDD"
  }

   const img2 = {
    "id": id2,
    "tile_url": tile_url2,
    "bbox": bbox,
    "min_zoom": 1,
    "max_zoom": 18,
    "type": "mosaic",
    "name": id2,
    "layoutRight": true,
    "layoutLeft": false,
	                "data_type": "raster_image",
                "selected": true,
                "opacity": 1,
                 "displayLabel": true,
                "map_type": "raster_image",
				 "color": "#00DDDD"
  }
  
  
  this.clear_preview_mosaic()
  
  this.add_preview_mosaic(window.map2,img1)
   this.add_preview_mosaic(window.map3,img1)
  this.add_preview_mosaic(window.map,img2)
  
  
  this.preview_mosaic1_id = img1.id
  this.preview_mosaic2_id = img2.id
  
  window.select_wms(window.map,'selected','selected',"qa_id=" + e.id)
  window.select_wms(window.map2,'selected','selected',"qa_id=" + e.id)
  window.select_wms(window.map3,'selected','selected',"qa_id=" + e.id)
  
  },
  
last_yymm(yymm) {
var lst = yymm.split('-')
var y = Number(lst[0])
var m = Number(lst[1])

var y1 = y
var m1 = m - 1

if (m1 < 1)
{
m1 = 12
y1 = y1 - 1
}

return String(y1) + '-' + (m1 < 10 ? '0' : '') + String(m1)
},
async  getDetectionsx(where){
 
 if (!this.yymm || !where)
 return
 
  const count_res = await getDetectionsx_Count(this.yymm,where)

	const count = count_res.data
	
	//const count = 200
	
	const pgsize = 4000
	
	var fetch = 0

	this.detections = []
	
	while (fetch < count)
	{
	
	  const res = await getDetections(this.yymm,where,fetch,pgsize)

	  //state.all_Detections = res.data.data
	  	
	this.detections = this.detections.concat(res.data)
	
	fetch = fetch + pgsize
	}
	
	 this.detections.forEach((d) => { 
	// d.typ_label = d.typ == 'gn' ? 'Green to Non Green' : 'Non Green to Green'
	 d.id1 = this.yymm + '_' + d.id
	 d.prov = 'Sumatera Selatan'
	 d.mine = 'Bata Bara'
	 d.aoi_area = this.aois_hash[d.aoi].area.toFixed(2)
	 d.pchg = (d.area/d.aoi_area * 100).toFixed(2)
	 d.preview = 'Preview'
	 }
	 )
	
  window.select_wms(window.map,this.yymm  + '_' + this.aoi.properties.NAME + '_Changes','detect',"aoi='" + this.aoi.properties.NAME + "' and after_del_mth = '" + this.yymm + "'")
  window.select_wms(window.map2,this.yymm  + '_' + this.aoi.properties.NAME + '_Changes','detect',"aoi='" + this.aoi.properties.NAME + "' and after_del_mth = '" + this.yymm + "'")
  window.select_wms(window.map3,this.yymm  + '_' + this.aoi.properties.NAME + '_Changes','detect',"aoi='" + this.aoi.properties.NAME + "' and after_del_mth = '" + this.yymm + "'") 
 
  },
async  getDetections(){
 
 if (!this.yymm || (this.aoi && !this.aoi.geometry))
 return
 
  const count_res = await getDetections_Count(this.yymm,this.aoi.properties.NAME)

	const count = count_res.data
	
	//const count = 200
	
	const pgsize = 4000
	
	var fetch = 0

	this.detections = []
	
	while (fetch < count)
	{
	
	  const res = await getDetections(this.yymm,this.aoi.properties.NAME,fetch,pgsize)

	  //state.all_Detections = res.data.data
	  	
	this.detections = this.detections.concat(res.data)
	
	fetch = fetch + pgsize
	}
	
	 this.detections.forEach((d) => { 
	// d.typ_label = d.typ == 'gn' ? 'Green to Non Green' : 'Non Green to Green'
	 d.id1 = this.yymm + '_' + d.id
	 d.prov = 'Sumatera Selatan'
	 d.mine = 'Bata Bara'
	 d.aoi_area = this.aois_hash[d.aoi].area.toFixed(2)
	 d.pchg = (d.area/d.aoi_area * 100).toFixed(2)
	 d.preview = 'Preview'
	 }
	 )
	
//window.select_wms(this.yymm  + '_' + this.aoi.properties.NAME + '_Changes','detect',"aoi='" + this.aoi.properties.NAME + "' and after_del_mth = '" + this.yymm + "'",this.aois_hash[this.aoi.properties.NAME].bbox )
  window.select_wms(window.map,this.yymm  + '_' + this.aoi.properties.NAME + '_Changes','detect',"aoi='" + this.aoi.properties.NAME + "' and after_del_mth = '" + this.yymm + "'")
  window.select_wms(window.map2,this.yymm  + '_' + this.aoi.properties.NAME + '_Changes','detect',"aoi='" + this.aoi.properties.NAME + "' and after_del_mth = '" + this.yymm + "'")
  window.select_wms(window.map3,this.yymm  + '_' + this.aoi.properties.NAME + '_Changes','detect',"aoi='" + this.aoi.properties.NAME + "' and after_del_mth = '" + this.yymm + "'") 
  },
clear_preview_mosaic() {
if (window.map.getStyle().layers.some(layer => layer.id === this.preview_mosaic2_id)) window.map.removeLayer(this.preview_mosaic2_id).removeSource(this.preview_mosaic2_id)
if (window.map2.getStyle().layers.some(layer => layer.id === this.preview_mosaic1_id)) window.map2.removeLayer(this.preview_mosaic1_id).removeSource(this.preview_mosaic1_id)
if (window.map3.getStyle().layers.some(layer => layer.id === this.preview_mosaic1_id)) window.map3.removeLayer(this.preview_mosaic1_id).removeSource(this.preview_mosaic1_id)

},
add_preview_mosaic(map,image)
   {  
 		
if (map.getStyle().layers.some(layer => layer.id === image.id)) map.removeLayer(image.id).removeSource(image.id)
		
	
 map.addSource(image.id, {
        'type': 'raster',
        'tiles': [image.tile_url],
        'tileSize': 256,
        'maxzoom': image.max_zoom || 18,
        'minzoom': image.min_zoom || 1,
        'bounds': image.bbox
      })
      const layer = {
        id: image.id,
        'tile_url': image.tile_url,
        'bbox': image.bbox,
        'color': 'black',
        'type': 'raster',
        'source': image.id,
        'name': image.name,
        'display': 'visible',
        'bounds': image.bbox,
        'layout': {
        },
        'paint': {
          'raster-fade-duration': 0
        }
      }

map.addLayer(layer)	  
  },  
  select_aoi2(aoi)
   {
   this.select_aoi(aoi,window.map)
    this.select_aoi(aoi,window.map2)
	this.select_aoi(aoi,window.map3)
   },
select_aoi(aoi,map)
   {  
  
  		if (window.selected_aoi_id)
		{
		
		if (map.getStyle().layers.some(layer => layer.id === window.selected_aoi_id)) map.removeLayer(window.selected_aoi_id).removeSource(window.selected_aoi_id)
		}
	
var id = aoi.properties.NAME

	
      map.addSource(id, {
        'type': 'geojson',
        'data': {
"type": "FeatureCollection",
"features": [aoi]
}
      })
      map.addLayer({
        'id': id,
        'type': 'line',
        'source': id,
        'layout': {},
        'paint': {
          'line-color': '#ff0000',
          'line-width': 2
        }
      })	

map.moveLayer(id)
window.selected_aoi_id = id
	  
  },
  changeTyp () {
	
       this.getDetections()
    },  
  changeYYMM () {
		this.yymm = this.yymm_item.yymm
		
		window.left_label.textContent = this.mmmyy(this.last_yymm(this.yymm))
		window.right_label.textContent = this.mmmyy(this.yymm)
		
       this.getDetections()
    },
changeAOI () {
		console.log(this.aoi)
		//return
		this.select_aoi2(this.aoi)
		
		this.$emit('zoomToImage', this.aoi)
		//console.log(this.groups)
     this.getDetections()
    },

	highlightFeatures()
	{
	
	    if (!window.select_id || window.select_id != this.selectedRows[0].id)
	   {
	   
		//window.select_wms('selected','selected',"qa_id=" + this.selectedRows[0].id)
	  
	  }
	},
    onSelectionChanged() {
      const selectedRows = this.gridApi
        .getSelectedNodes()
        .map((node) => node.data)
      this.selectedRows = selectedRows
	  this.highlightFeatures()
	  this.lastselectedRows = selectedRows
      //map.call('TableSelectorModule', 'removeHighlightedRow')
	  if (selectedRows.length == 1)
	  {
	   var g = selectedRows[0].bounds
	  if (!g)
	  {
	  const b = selectedRows[0].ullr.split(',')
	   g = {type:'Feature',geometry: {type:'Polygon',coordinates: [[
	  [b[0],b[3]],
	  [b[2],b[3]],
	  [b[2],b[1]],
	  [b[0],b[1]],
	  [b[0],b[3]]
	  ]]}}
	  }
	   
	  
	   this.zoomBounds(g ,selectedRows[0].aoi)
	   }
      selectedRows.forEach((item) => {
	 
       // map.call('TableSelectorModule', 'setHighlightedRow', item.geometry)
      })
    },	
    onFeatureSel(id) {
      let data = this.localData.find((x) => x.id == id)
      this.gridApi.ensureNodeVisible(data)
      const node = this.gridApi.getRowNode(id)
      if (!node) return
      node.setSelected(true)
    },
    onFeatureUnSel(id) {
 
      map.call('CameraModule', 'flyToGeoJSON', geojson, {displayFeature: false})
    },
 
  }
}
</script>


<style>

input[type="checkbox"]:disabled {
  
  color: blue;
}

.VueTables__row--selected {
    background-color: #e5956d;
}

.margin_auto {
    margin: auto;
	width: 50%;
}

td {
  text-align: center;
}
</style>

<style scoped>
.ag-header-container {

width : 1200px;

}

.row {
  display:flex;
  flex-direction:row;
  align-items: center;
  width: 100%;
}

.column {
  display:flex;
  flex-direction:column;
  align-items: flex-start;
}

.table-wrapper {
  height: 100%;
  background-color: white;
}

.table-content {
  padding: 10px;
  height: calc(100% - 45px);
}

.table-title {
  padding: 8px 20px;
  color: steelblue;
  border-top: 1px gainsboro solid;
  border-bottom: 1px gainsboro solid;
}

.table-title span {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.content-message {
  width: 100%;
  color: rgba(134, 134, 134, 0.7);
  font-size: 14px;
  text-align: center;
}

.table-tag:hover {
  cursor: pointer;
}
.action-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  font-size: 12px;
  color: white;
  background-color: var(--color-primary-dark);
  border-radius: 5px;

}
</style>
