<template>

        <v-card flat class="fill-height d-flex flex-column pt-4">
            <!-- <span @click="() => $emit('hideSideBar')" style="font-size: 14px; color: #893FF2; cursor: pointer"><v-icon color="#893FF2">mdi-chevron-double-left</v-icon>Hide Sidebar</span> -->

              <p class="text-body2 panelTabHeader pl-2 pb-2">DAILY VIEW

                <v-btn class="float-end my-0 py-0" outlined color="#893FF2" @click="addVectorLyr">Add All AOI</v-btn>
              </p>
              
              <v-row class="mx-1" style="border-radius: 11px; border: solid 1px #893FF2; height:40px;">
                <v-col cols="6" class="pa-2 pl-1">
              <dualDate 
                @changedDate='newDate' 
                :disableTime="true">
              </dualDate>
                </v-col>
                <v-col cols="6" class="pa-2 searchBoxPosition" style="border-left: solid 2px #C4C4C4; border-radius: 12px; height: 40px;">
                  <searchBox @aoiSelected="newAOI"></searchBox>
                </v-col>
              </v-row>
          <div style="width: 100%; height: 10px;" class="py-5">
            <v-layout class="px-3 mb-6" style="height: 50px">
              
                <span style="color:#893FF2;" class="mr-2">Cloud Cover: </span>
                    <v-slider
                        v-model="cloud"
                        @change="changeCloud()"
                        :max="100"
                        :min="0"
                        step="10"
                        tick-size="2"
                        thumb-label
                        :tick-labels="cloudLabels"
                        ticks="always"
                        color="#399be9"
                        track-color="#893FF2"
                        
                        class="px-2 dailyCloud"
                    ></v-slider>
            </v-layout>
          </div>

          <!-- <p>{{groups.groups}}</p> -->

           <v-layout column class="overflow-y-auto pb-3 resultsContainer mt-6" style="height: 100%">
             <!-- <v-expansion-panels multiple flat style="height:455px;" class="s-expansionPanel"> Server -->
            <v-expansion-panels multiple flat style="height:385px;" class="s-expansionPanel">
              <v-expansion-panel v-for="(group, idx) of groups.groups" :key="idx">
                <v-expansion-panel-header>
                  <span style="text-transform: uppercase; font-weight: bold" ><v-icon class="mr-3">mdi-folder-outline</v-icon>{{group.date}}</span>
                  <v-spacer/>
                  <span class="mr-2" style="text-align: end; font-size: 12px;" @mouseenter="previewAllGroup(group)" @mouseleave="removeImageBnd">
                   
                    (Total: {{Object.keys(group.strips).length}} strip)  
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  
                  
                  <!-- Sub Folder START-->
                  <v-expansion-panel v-for="(grp, idx) of group.strips" :key="idx" >
                    <v-expansion-panel-header class="px-2 py-2" style="border: solid 1px #a5a5a5; margin:3px 0px;">
                      <span>
                        <v-icon class="float-start mr-2">mdi-folder-outline</v-icon> 
                        <span style="vertical-align: -webkit-baseline-middle; font-weight: bold; font-size: 14px;">{{new Date(grp.time).toTimeString().substring(0,5)}}</span>
                      </span>
                      <v-spacer/>
                      <span class="mr-2" style="text-align: end; font-size: 12px;"  @mouseenter="previewGroup(grp)" @mouseleave="removeImageBnd">
                          (Total: {{Object.keys(grp.images).length}} image)  
                            <v-icon @click.native.stop="stripclick(grp)" style="margin-left:5px; margin-right: 12px; flex-shrink: 1;" >mdi-plus-circle-outline</v-icon> 
                        </span>
                    </v-expansion-panel-header>

                    <!-- Sub Folder Content START -->
                    <v-expansion-panel-content  class="subFolder">
                          <v-list dense class="px-2 py-2">
                            <v-list-item v-for="(item, index) in grp.images" class="elevation-1 my-2 pl-0" :key="index" style="border-radius: 5px; border-left: solid 5px #536dfe" @click="itemclick(item)">
                              <v-layout column>
                                
                                <v-layout style="height: 35px" align-center>
                                  <v-layout class="fill-height ml-3" align-center   
                                      @mouseenter="showImageBnd([item])"
                                      @mouseleave="removeImageBnd">
                                    <span style="font-size: 13px">{{item.id}}</span>
                                    <v-spacer/>
                                    <img :src="item.thumb" width="50px" height="50px"/>
                                  </v-layout>
                                </v-layout>
                                    <v-row>
                                <v-col cols="4" class="text-center">
                                  <v-icon>mdi-weather-cloudy</v-icon> {{item.properties.cloud_cover}}
                                </v-col>
                                <v-col cols="4" class="text-center">
                                  <v-icon>mdi-timer-outline</v-icon> {{ ("0" + new Date(item.properties.acquired).getHours()).slice(-2)  }}:{{ ("0" + new Date(item.properties.acquired).getMinutes()).slice(-2) }}:{{ ("0" + new Date(item.properties.acquired).getSeconds()).slice(-2)}}
                                </v-col>
                                <v-col cols="4" class="text-center">
                                  <v-icon>mdi-angle-acute</v-icon> {{item.properties.view_angle}}
                                </v-col>
                              </v-row>
                              </v-layout>
                            </v-list-item>
                          </v-list>
                    </v-expansion-panel-content>
                     <!-- Sub Folder Content END-->
                  </v-expansion-panel>
                   <!-- Sub Folder END -->


                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-layout>

          <!-- <v-layout column class="overflow-y-auto pb-3 resultsContainer" style="height: 100%">
            <v-expansion-panels multiple flat style="height:455px;" class="s-expansionPanel">
              <v-expansion-panel v-for="(group, idx) of groups" :key="idx">
                <v-expansion-panel-header>
                  <span style="text-transform: uppercase; font-weight: bold"><v-icon class="mr-3">mdi-folder-outline</v-icon>{{group.name}}</span>
                  <v-spacer/>
                  <span class="mr-2" style="text-align: end; font-size: 12px;">(Total: {{group.images.length}} image)</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list dense>
                            <v-list-item v-for="(item, index) in group.images" class="elevation-1 my-2 pl-0" :key="index" style="border-radius: 5px; border-left: solid 5px #536dfe" @click="itemclick(item)">
                              <v-layout column>
                                
                                <v-layout style="height: 35px" align-center>
                                  <v-layout class="fill-height ml-3" align-center   
                                      @mouseenter="showImageBnd([item])"
                                      @mouseleave="removeImageBnd">
                                    <span style="font-size: 13px">{{item.name}}</span>
                                    <v-spacer/>
                                    <img :src="item.thumb" width="50px" height="50px"/>
                                  </v-layout>
                                </v-layout>
                                    <v-row>
                                <v-col cols="4" class="text-center">
                                  <v-icon>mdi-weather-cloudy</v-icon> {{item.properties.cloud_cover}}
                                </v-col>
                                <v-col cols="4" class="text-center">
                                  <v-icon>mdi-timer-outline</v-icon> {{ ("0" + new Date(item.properties.updated).getHours()).slice(-2)  }}:{{ ("0" + new Date(item.properties.updated).getMinutes()).slice(-2) }}:{{ ("0" + new Date(item.properties.updated).getSeconds()).slice(-2)}}
                                </v-col>
                                <v-col cols="4" class="text-center">
                                  <v-icon>mdi-angle-acute</v-icon> {{item.properties.view_angle}}
                                </v-col>
                              </v-row>
                              </v-layout>
                            </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-layout> -->
        </v-card>

</template>

<script>
import aois_geojson from '@/assets/aoisx.json'
import FullScreenSquare from "@/components/imagery/FullScreenSquare";
import {mapState} from "@/store/ults";
import Store from "@/store";
import DatePicker from "@/components/DatePicker";
import search from "@/components/home/aoiMenu";
import dualDate from "@/components/dualDateRangePicker";

export default {
  name: "Daily",
  components: {
    DatePicker, 
    FullScreenSquare,
    'searchBox' : search, 
    dualDate   
    },
  data () {
    return {
      cloudLabels : ['0',10,20,30,40,50,60,70,80,90,100],
      cloud : 30,
	    //groups : {},
	    aoi : {},
      aois: [],
      tabs: 'data',
      mode: 'type',
      //dateSelection: require('@/assets/dateSelect.json'),
      date: {from : '' , to : ''},
      aoiProvince : 'province',
      items: ''
    }
  },
  props: {
   //groups: {type: Array, default: () => []}, 
  },
  watch: {

  },
  computed: {
    ...mapState("point", ["currentPoint"]),
	  ...mapState('image', ['groups']),
  },
  mounted() {
  aois_geojson.features.forEach((f) => {
    var x1 = 999999999
    var x2 = -999999999
    var y1 = 999999999
    var y2 = -999999999
    var c = f.geometry.coordinates[0]

    for (var a = 0; a < c.length; a++ ) {
      if (c[a][0] < x1)
        x1 = c[a][0]
      if (c[a][0] > x2)
        x2 = c[a][0]	
      if (c[a][1] < y1)
        y1 = c[a][1]
      if (c[a][1] > y2)
        y2 = c[a][1]		
    }
      
      f.bbox =  [x1,y1,x2,y2
]
      
      })
    this.aois = aois_geojson.features
    this.date.from = this.fromDate
    this.date.to = this.toDate
    // console.log(this.groups)
  },
  methods: {
      addVectorLyr(){
        this.$emit("addVectorLyr")
      },
      newDate(date){
        if (date[1]){
          this.date.from = date[0].split("-").reverse().join("-")
          this.date.to = date[1].split("-").reverse().join("-")
        }
        //console.log('AOI of New Date:', this.aoi)

        if(this.aoi.properties.name = undefined){
          //console.log('Date: ',date, ' AOI: ', this.aoi)
          return
        }
        else if (this.aoi.name) {
          this.changeAOI();
        }
      },
      newAOI(x){
        // console.log('new AOI',x)
        this.aoi = x;

        // console.log('New AOI: ' ,this.aoi)
        //console.log('New AOI,')

        if(this.date.from && this.date.to){ 
          this.changeAOI();
        }
      },
	  stripclick(grp) {
	  
        // Emmitted when layer results are clicked
		grp.images.forEach(i => {
		i.AoiName = this.aoi.name
          this.$emit('changeListLayer', i)
        })
        this.zoomStrip(grp)
        
      },
      itemclick(i) {
        // Emmitted when layer results are clicked
		i.AoiName = this.aoi.name
        this.$emit('zoomToImage', i)
        this.$emit('changeListLayer', i)
      },
      previewAllGroup(grps) {
        var imgs = []
        Object.values(grps.strips).forEach(s => {
          s.images.forEach(image => {
           imgs.push(image)
          })
        })
		
		this.showImageBnd(imgs)
      },
 
      previewGroup(strips) {
	  var imgs = []
        Object.values(strips.images).forEach(s => {
          imgs.push(s)
        })
		
		this.showImageBnd(imgs)
      },
     zoomStrip(strip) {
          const features = []
       
            strip.images.forEach((image) => {
              features.push({
                type: 'Feature',
                geometry: image.geometry
              })
            })
      
          const bounds = {
            type: 'FeatureCollection',
            features: features
          }
          this.$emit('zoomToImage', bounds)
      },
      zoomAll(strips) {
          const features = []
          Object.values(strips).forEach((strip) => {
            strip.images.forEach((image) => {
              features.push({
                type: 'Feature',
                geometry: image.geometry
              })
            })
          })
          const bounds = {
            type: 'FeatureCollection',
            features: features
          }
          this.$emit('zoomToImage', bounds)
      },
      showImageBnd(imgs){
		
          var features = []
          imgs.forEach(i => {
          features.push(
                            { 
                              "type": "Feature" , 
                              "geometry" : i.geometry
                            }
                          )
                      })
		// console.log(features)
        var id = 'imgbnd'
            map.addSource(id, {
              'type': 'geojson',
              'data': {
                "type": "FeatureCollection",
                "features": features
                    }
                })
            map.addLayer({
              'id': id,
              'type': 'line',
              'source': id,
              'layout': {},
              'paint': {
                'line-color': '#ffa500',
                'line-width': 2
              }
            })	

            map.moveLayer(id)
        },
        removeImageBnd(){
          var id = 'imgbnd'
          if (map.getStyle().layers.some(layer => layer.id === id)) map.removeLayer(id).removeSource(id)
            
        },
        select_aoi(aoi)  {
                      var map = window.map
                        if (window.selected_aoi_id)
                        { 
                          // console.log()
                          if (map.getStyle().layers.some(layer => layer.id === window.selected_aoi_id)) map.removeLayer(window.selected_aoi_id).removeSource(window.selected_aoi_id)
                        }
                        // console.log('Select AOI', window.selected_aoi_id);
                      // var id = aoi.properties.layer
                      // var id = aoi.properties.name
                      //console.log('Error Here?', aoi)
                      var id = aoi.name
                      var i = 
                      {
                        "id": id,
                        "name": id,
                        "list_values": [],
                        "geometry" :  {
                          "type": "FeatureCollection",
                          "features": [aoi]
                        },
                        "bbox": aoi.bbox,
                        "type": "AOI",
                        "data_type": "vector",
                        "selected": true,
                        "layoutRight": true,
                        "opacity": 1,
                        "layoutLeft": true,
                        "displayLabel": true,
                        "map_type": "vector",
                        "color": "#FF0000"
                      }
                      // console.log(i);
              this.$emit('zoomToImage', i)
              this.$emit('changeListLayer', i)
              //console.log('After Select AOI: ', this.aoi)
              //console.trace();

      /*	
            map.addSource(id, {
              'type': 'geojson',
              'data': {
      "type": "FeatureCollection",
      "features": [aoi]
      }
            })
            map.addLayer({
              'id': id,
              'type': 'line',
              'source': id,
              'layout': {},
              'paint': {
                'line-color': '#ff0000',
                'line-width': 2
              }
            })	

      map.moveLayer(id)
      */
      window.selected_aoi_id = id
          
        },
        changeDate () {
              this.getimgs()
            },
        changeAOI () {
              // console.log(this.aoi);
              //console.log('Change AOI', this.aoi);
              this.select_aoi(this.aoi)
              // console.log('Done with select AOI')
              // console.log('Done with Select')
              this.$emit('zoomToImage', this.aoi)
              // console.log('Done with Zoom')
              //console.log(this.groups)
              this.getimgs()
              // console.log('Done with getImg')
          },
        changeCloud () {
          if(this.date.to && this.aoi){
            console.log('Launched!')
            this.getimgs()
          }
        },
          
        getimgs() {
          // console.log('Sending Request before Error?')
          // console.log('AOI: ' ,this.aoi, 'Date: ', this.date, 'Cloud: ', this.cloud)
            if (this.aoi && this.aoi.geometry && this.date.from && this.date.to)
            {
                this.$store.dispatch('image/getImages',{aoi: this.aoi , date: this.date, cloud : this.cloud})
            }
            //console.log('getImgs: ',this.aoi)
        }
    }
}
</script>

<style scoped>

.s-expansionPanel {
  display: block !important;
}

/deep/
.sidebar-tab .v-tabs-bar {
  height: 45px !important;
}
/deep/
.small-radio .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
  background: currentColor;
  transform: scale(0.9);
  transition: none;
}

div.v-menu__content.menuable__content__active{
  top: 323px !important;
}

</style>

<style>

.dailyCloud .v-input__control .v-input__slot .v-slider--horizontal .v-slider__ticks-container .v-slider__tick-label {
    font-size: 10px;
}

.subFolder .v-expansion-panel-content__wrap {
    padding: 0 8px 8px !important;
}

.panelTabHeader{
  font-weight: bold;
  font-size: 14.5px;
  line-height: 17px;
  letter-spacing: 0.05em;
  color: #8539F0;
}

.searchBoxPosition button{
  transform: translate(-6px, -7px);
}

.searchBoxPosition button span{
  color: #8539F0;
  font-weight: 700;
}
</style>
