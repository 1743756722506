<template>
  <v-layout style="width: 100%; height: 100%">
    <Home/>
  </v-layout>
</template>
<script>
import Home from '@/components/home/index'
export default {
  components: { Home }
}
</script>
<style>
.textWhite{
  color: white;
}

.textWhiteImp{
  color: rgb(104, 100, 100) !important;
}

.textStress{
    color: #FF0000;
}
.textSlightStress{
    color: #ED7D31;
}
.textModerate{
    color: #B8BB23;
}
.textHealthy {
    color: #649F25;
}
.textVeryHealthy{
    color: #375623;  
}

.bgStress{
    background-color: #FF0000;
}
.bgSlightStress{
    background-color: #ED7D31;
}
.bgModerate{
    background-color: #B8BB23;
}
.bgHealthy {
   background-color: #649F25;
}
.bgVeryHealthy{
    background-color: #375623;  
}

.greenCoverColor {
    color: #893FF2;
}

.bgPurple {
    background: #893FF2;
}

.NonGreenColor {
    color: #9C7A00;
}
.WaterColor{
    color: #1F7BB0;
}
.CloudColor {
    color: #D17E00;
}

.HDenseColor{
    color: #04752B;
}
.DenseColor{
    color: #28ECB5;
}
.LDenseColor{
    color: #D10950;
}

.lh1-2Rem{
    line-height: 1.2rem;
}


</style>