<template>

  <div class="d-flex fill-height justify-center flex-column pt-4" style="width: 100%; background-color: #f4ecfd">
    <v-overlay :value="loading" absolute>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
   <splitpanes class="default-theme" @resized="resize_maps" horizontal>
   <pane :size="paneSize">
    <v-layout style="width: 100%; height: 100%; background-color: #f4ecfd; position: relative" column>
      <v-layout style="width: 100%; height: 100%;" class="pa-0">
        <v-layout column style="height: 100%; background-color: #f4ecfd;" align-center>
          <v-card style="border-radius: 10px; position: absolute; top: 60px; right: 10px; z-index: 3" width="460px" height="35" class="px-2 mb-4">
            <v-layout justify-space-between align-center class="fill-height" style="height: 100%">
              <v-btn-toggle
                  dense
                  v-model="mapStatus"
                  mandatory
                  color="deep-purple accent-3"
                  group
                  class="d-flex justify-space-between flex-grow-1"
              >
                <v-btn width="85" min-height="0" height="26" value="overlay"
                       :style="{'border': mapStatus === 'overlay'? '1px solid #893FF2' : '', 'border-radius': mapStatus === 'overlay'? '8px' : '0'}">
                  <span style="padding-top: 3px; font-size: 12px">Overlay</span>
                </v-btn>

                <v-btn width="85" min-height="0" height="26" value="slide"
                       :style="{'border': mapStatus === 'slide'? '1px solid #893FF2' : '', 'border-radius': mapStatus === 'slide'? '8px' : '0'}">
                  <span style="padding-top: 3px; font-size: 12px">Slide</span>
                </v-btn>
                <v-btn width="85" min-height="0" height="26" value="sync"
                       :style="{'border': mapStatus === 'sync'? '1px solid #893FF2' : '', 'border-radius': mapStatus === 'sync'? '8px' : '0'}">
                  <span style="padding-top: 3px; font-size: 12px">Sync</span>
                </v-btn>
                <!-- <v-btn width="85" min-height="0" height="26" value="change"
                       :style="{'border': mapStatus === 'change'? '1px solid #893FF2' : '', 'border-radius': mapStatus === 'change'? '8px' : '0'}">
                  <span style="padding-top: 3px; font-size: 12px">Change</span>
                </v-btn>				 -->
                <!--
                <v-btn width="85" min-height="0" height="26" value="time"
                :style="{'border': mapStatus === 'time'? '1px solid #893FF2' : '', 'border-radius': mapStatus === 'time'? '8px' : '0'}">
                <span style="padding-top: 3px; font-size: 12px">Time</span>
                </v-btn> 
                -->
              </v-btn-toggle>
            </v-layout>
          </v-card>
          <div class="d-flex flex-column panelContainer" 
              style="width: 550px; 
              height: 720px; 
              position: absolute; 
              left: 0; top: 55px; 
              z-index: 3; 
              margin-left: 30px; 
              border-radius: 30px ;" v-if="tabsShow">
            <div style="
                border-radius: 12px 12px 12px 12px; 
                overflow: hidden; 
                background-color: white; 
                width: 550px; 
                height: 100%; 
                border-top: 2px solid #8539F0;
                border-right: 2px solid #8539F0; 
                padding-right: 0px;"
                class="pt-0 flex elevation-24">
              <v-row
                class="ma-0 pa-0"
                style="border-radius: 12px 12px 0 0;
                       border-left: 2px solid #8539F0;
                       border-bottom: 3px solid #DFCEF8;"
                >
                    <v-col cols="8" class="pa-0">
                      <p class="ml-4 mt-3 font-weight-bold libraryHeader">DATA LIBRARY</p>
						                     <!-- <v-btn @click="removeVectorLyr">Remove</v-btn>
                      <v-btn @click="addVectorLyr">Add</v-btn> -->
                    </v-col>
                    <v-col cols="4" class="pa-0">
                        <v-btn class="hideLeftBtn" @click="tabsShow=false">
                          <v-icon>mdi-pan-left</v-icon>
                          <!-- <v-icon>mdi-arrow-left-drop-circle-outline</v-icon> -->
                          Hide Sidebar
                        </v-btn>
                    </v-col>
              </v-row>
                  
              <v-row
                class="my-0 mx-0"
               >	
               <!-- Main Panel Column -->
                <v-col :cols="2" class="verticalTabColumn py-0 pl-0" style="border-radius: 0 0 0 12px; border-right: solid 3px #893FF2;">				 
                  <v-tabs
                      class="VerticalLeftTab"
                      style="flex:none; height: 657px"
                      v-model="tabs"
                      fixed-tabs
                      color="#893FF2"
                      vertical
                      slider-size="3"
					 
                    >
                      <v-tab
                          href="#daily"
                          style="text-transform: none;"
                          :style="{'background-color': tabs === 'daily' ? '' : 'rgba(137,63,242,0.22)'}"
                          class="flex-column px-0"
                      >
                        <v-icon class="my-3">mdi-update</v-icon>
                        DAILY
                      </v-tab>

                      <v-tab
                          href="#data"
                          style="text-transform: none;"
                          :style="{'background-color': tabs === 'data' ? '' : 'rgba(137,63,242,0.22)'}"
                          class="flex-column px-0"
                      >
                      <v-icon class="my-3">mdi-database</v-icon>
                        DATA
                      </v-tab>    
                      <v-tab
                          href="#tasking"
                          style="text-transform: none;"
                          :style="{'background-color': tabs === 'tasking' ? '' : 'rgba(137,63,242,0.22)'}"
                          class="flex-column px-0"
                      >
                        <v-icon class="my-3">mdi-cart-arrow-down</v-icon>
                        BUY DATA
                      </v-tab>
                      <v-tab
                          href="#change"
                          style="text-transform: none;"
                          :style="{'background-color': tabs === 'change' ? '' : 'rgba(137,63,242,0.22)'}"
                          @click="changeMapStatus"
                          class="flex-column px-0" >
                        <v-icon class="my-3">mdi-sync</v-icon>
                        CHANGE
                      </v-tab>  
                    </v-tabs>	
                </v-col>	
                <!-- If trigger column to hide below, it will leave blank content, but leaves a white background, because the parent container is a flexbox-->
                <v-col :cols="10" class="py-0" style="border-bottom: 2px solid #8539F0;" >
                            <v-tabs-items   v-model="tabs" class="fill-height">
                                <v-tab-item
                                    style="height : 600px"
                                    value="daily"
                                    class="fill-height">	
                                  <v-card flat>	  
                                      <Daily
                                        ref="dailyView"
                                        @changeListLayer="changeListLayer"
                                        @zoomToImage="zoomToImage"
                                        @addVectorLyr="addVectorLyr"/>
                                  </v-card>
                              </v-tab-item>	

                            <v-tab-item
                                style="height : 600px"	  
                                value="data"
                                class="fill-height">			
                                    <v-card flat>	  
                                      <Data
                                              ref="Data"
                                              :groupType.sync="groupType"
                                              :groups.sync="groupImage"
                                              :areas.sync="aois"
                                              @input="changeGroupBy"
											                        @zoomToImage="zoomToImage"
                                              @changeListLayer="changeListLayer"
                                              @changeMonth="changeMonth"
											                        @changeAOI="changeAOI"
                                              :fromMonth.sync="fromMonth"
                                              :toMonth.sync="toMonth"/>
                                    </v-card>
                            </v-tab-item>

                            <v-tab-item
                                  value="tasking"
                                  class="fill-height">	
                              <v-card flat class="text-center mt-4 text-h5">	  
                                 FEATURE COMING SOON
                              </v-card>
                            </v-tab-item>	

                            <v-tab-item
                                      value="change"
                                      class="fill-height"
                                  >	
                            <!-- <v-card flat>	  
                                  Change
                                </v-card> -->
                                <v-card flat class="text-center mt-4">	  
                                 <p class="text-h5">Please contact <a style="text-decoration-color: #893ff2; color: #893ff2;" href="mailto:sales@skymapglobal.com">sales@skymapglobal.com</a> <br>to add-on this feature.</p>
                              </v-card>                           
                            </v-tab-item>				
                </v-tabs-items>	 
                </v-col>
              </v-row>
              
                   
              </div>
			  <!-- <div style="height: 50px; width: 100%; flex: none">
              <img src="@/assets/images/reverse-rounded-footer.png" style="margin-left: -6px">
            </div> -->
          </div>
		  
		    <div class="d-flex flex-column" 
            style="width: 90px; 
            height: 350px; 
            position: absolute; 
            left: 0; 
            top: 30px; 
            z-index: 3; 
            margin-left: 30px; 
            border-radius: 12px; 
            border:solid 3px #893FF2;" 
            v-if="!tabsShow">
            <div
                 style=" 
                  overflow: hidden; 
                  background-color: white; 
                  width: 100%; 
                  height: 100%; 
                  border-top-right-radius: 12px; 
                  border-top: 2px solid #deccf8; 
                  border-right: 2px solid #deccf8; 
                  padding-right: 0px"
                 class="pt-0 flex elevation-24">
                
          
                <!-- Hidden Panel -->
                <v-col :cols="2" class="verticalTabColumn py-0 pl-0" style="width:90px;">				 
                  <v-tabs
                      class="MiniVerticalLeftTab flex-shrink-1"
                      style="flex:none; height: 350px;width:90px;"
                      v-model="tabs"
                      color="#893FF2"
                      vertical
                      hide-slider
                      icons-and-text
                      grow
					 
                    >
                      <v-tab
                          href="#daily"
                          style="text-transform: none;"
                          :style="{'background-color': tabs === 'daily' ? '' : 'rgba(137,63,242,0.22)'}"
                          class="px-0 "
						              @click="change_closed"
                      >
                        DAILY
                        <v-icon class="my-3">mdi-bank</v-icon>
                      </v-tab>

                      <v-tab
                          href="#data"
                          style="text-transform: none;"
                          :style="{'background-color': tabs === 'data' ? '' : 'rgba(137,63,242,0.22)'}"
                          class="px-0 "
						                @click="change_closed"
                      >
                          DATA
                        <v-icon class="my-3">mdi-database</v-icon>
                      </v-tab>    
                      <v-tab
                          href="#tasking"
                          style="text-transform: none;"
                          :style="{'background-color': tabs === 'tasking' ? '' : 'rgba(137,63,242,0.22)'}"
                          class="px-0 "
						                @click="change_closed"
                      >
                        BUY DATA
                        <v-icon class="my-3">mdi-cart-arrow-down</v-icon>
                      </v-tab>
                      <v-tab
                          href="#change"
                          style="text-transform: none;"
                          :style="{'background-color': tabs === 'change' ? '' : 'rgba(137,63,242,0.22)'}"
                          @click="changeMapStatus"
                          class="px-0 " >
                        CHANGE
                        <v-icon class="my-3">mdi-sync</v-icon>
                      </v-tab> 

                      <!-- <v-tab
                          href="#daily"
                          style="text-transform: none;"
                          :style="{'background-color': tabs === 'daily' ? '' : 'rgba(137,63,242,0.22)'}"
                          class="flex-column px-0"
						   @click="change_closed"
                      >
                        <v-icon class="my-3">mdi-bank</v-icon>
                        DAILY
                      </v-tab>

                      <v-tab
                          href="#mosaic"
                          style="text-transform: none;"
                          :style="{'background-color': tabs === 'mosaic' ? '' : 'rgba(137,63,242,0.22)'}"
                          class="flex-column px-0"
						   @click="change_closed"
                      >
                      <v-icon class="my-3">mdi-database</v-icon>
                        DATA
                      </v-tab>    
                      <v-tab
                          href="#tasking"
                          style="text-transform: none;"
                          :style="{'background-color': tabs === 'tasking' ? '' : 'rgba(137,63,242,0.22)'}"
                          class="flex-column px-0"
						   @click="change_closed"
                      >
                        <v-icon class="my-3">mdi-cart-arrow-down</v-icon>
                        BUY DATA
                      </v-tab>
                      <v-tab
                          href="#change"
                          style="text-transform: none;"
                          :style="{'background-color': tabs === 'change' ? '' : 'rgba(137,63,242,0.22)'}"
                          @click="changeMapStatus"
                          class="flex-column px-0" >
                        <v-icon class="my-3">mdi-sync</v-icon>
                        CHANGE
                      </v-tab>  	 -->
                    </v-tabs>	
                </v-col>	
              
              
              
                   
              </div>
			  <!-- <div style="height: 50px; width: 100%; flex: none">
              <img src="@/assets/images/reverse-rounded-footer.png" style="margin-left: -6px">
            </div> -->
          </div>
		           
          <BaseMap @inspectImage="inspectImage" @changeListLayer="changeListLayer" ref="mapView" :mode.sync="mapStatus" :dataSource.sync="currentDatasource"/>
          <LayerControl
              @toggleLabel="(status) => $refs.mapView.toggleLabel(status)"
              @changeViewImage="changeViewImage"
              @changeDisplayImage="(image, beforeId) => $refs.mapView.changeDisplayImage(image, beforeId)"
              @changeViewImageLeftMap="changeViewImageLeftMap"
              @changeViewImageRightMap="changeViewImageRightMap"
              @zoomToImage="zoomToImage"
              @updateOpacity="changeOpacityValue"
              @closeLayerControl="showLayerControl = false"
              @newMoveLayer="newMoveLayer"
              @removeLayer="removeCurrentLayer"
              :layers.sync="layers"
              :areas.sync="aois"
              :currentAOI="currentAOI"
              ref="layerControl"
              :mapStatus="mapStatus"
              v-if="showLayerControl"
              style="position: absolute; top: 100px; right: 10px"
          >
          </LayerControl>
        </v-layout>
      </v-layout>
    </v-layout>


	<!-- <div v-if="!tabsShow">
	    <v-btn  v-if="1 == 2 && isChgShow == false" @click="showChangeDetection" outlined style="background-color: white; position: absolute; bottom: 0; left: 50%" color="#893FF2" height="30" width="190" min-width="0">
      <span style="">
        <v-icon color="#893FF2">mdi-chevron-double-up</v-icon>
		Change Detection
      </span>
    </v-btn>
  <v-btn
        fab
        :outlined="!showLayerControl"
        @click="showLayerControl = !showLayerControl"
        :style="{'background-color': showLayerControl ? '#893FF2' : '#fff' }"
        style="position: absolute; top: 160px; right: 10px" :color="showLayerControl ? '#893FF2' : '#fff'" height="40" width="40" min-width="0">
      <span>
        <v-icon :color="showLayerControl ? '#fff' : '#893FF2'">mdi-layers</v-icon>
      </span>
    </v-btn>
	</div> -->
	<div>
  <v-btn
        :outlined="!showLayerControl"
        @click="showLayerControl = !showLayerControl"
        :style="{'background-color': showLayerControl ? '#893FF2' : '#e5d5fc', 'display': showLayerControl ? 'none' : 'block'  }"
        style="position: absolute; top: 18%; right: -72px; transform: rotate(270deg); border-radius: 12px;" 
        :color="showLayerControl ? '#fff' : '#893FF2'"
        >
      
      <span>
        OPEN LAYER TAB
       
      </span>
    </v-btn>
	</div>

	</pane>
   <!-- <pane :size="100 - paneSize" v-if="!tabsShow && isChg && isChgShow">
        
              <ChangeTable  
                @zoomToImage="zoomToImage" 
                @changeListLayer="changeListLayer"
                @hideChangeDetection="hideChangeDetection"
              />
         	
   </pane> -->
  </splitpanes>
   
 
  </div>
</template>
<script>
import {
  getGeom,
  getAOI,
  getImageries, inspectData, inspectImageData,
} from "@/backend"
import {debounce} from "lodash"
import {mapState} from "@/store/ults"
import PopupCreateUser from "@/components/user/CreateUser"
import BaseMap from "@/components/imagery/BaseMap"
import PopupTimeSeries from "@/components/imagery/TimeSeriesView"
import LayerControl from "@/components/imagery/LayerControl";
import Daily from "@/components/imagery/Daily";
import ChangeTable from "@/components/imagery/ChangeTable";
import Data from "@/components/imagery/Data";
import randomColor from "@/ultis/randomColor";
import bbox from "@turf/bbox";
import numberFormat from "@/ultis/comma";
import Store from "@/store";
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import aois_geojson from '@/assets/AOI_Planet.json'
import overviewAPI from "@/api/overview.js";

export default {
  components: {
   Splitpanes, 
   Pane,
  ChangeTable,
    Daily,
    Data,
    LayerControl,
    PopupTimeSeries,
    BaseMap,
    PopupCreateUser
  },
  data() {
    return {
	aoi : {},
	paneSize_prev : 60,
	paneSize : 60,
	isChg : false,
	isChgShow : true,
	  tabs : 'daily',
      aois: [],
      refData: undefined,
      groupType: 'type',
      showLayerControl: true,
      tabsShow: true,
      mode: 'app/image-detail',
      compareMap: false,
      allImage: [],
      tab: null,
      items: [
        'All', 'Satellite View', 'Result obtabined'
      ],
      layers: [],
      mapStatus: 'overlay',
	  mapStatus2: '',
      loading: false,
      users: [],
      fromMonth: '2021-01',
      toMonth: '2021-09',
      months: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
      currentAOI:'',
      propsAOI:'',
      vecLayer: {},    }
  },
  props: {
    currentDatasource: {}
  },
  computed: {
    ...mapState("auth", ["currentUser"]),
    groupImage () {
      let groups = []
      switch (this.groupType) {
        case 'type':
          this.allImage.forEach(image => {
           
              let name = ''
			   let index = 0
			   
              switch (image.type.replaceAll('_', ' ')) {
                case 'classification': name = 'Green Cover'
			index = groups.findIndex(group => group.type === image.type)
              if (index >= 0) groups[index].images.push(image)
              else groups.push({
                name: name,
                type: image.type,
                images: [image]
              })
                  break
                case 'green density': name = 'Green Density'
				index = groups.findIndex(group => group.type === image.type)
              if (index >= 0) groups[index].images.push(image)
              else groups.push({
                name: name,
                type: image.type,
                images: [image]
              })
              break
                case 'Satellite Imagery Mosaic': name = 'Satellite Imagery Mosaic'
			index = groups.findIndex(group => group.type === image.type)
              if (index >= 0) groups[index].images.push(image)
              else groups.push({
                name: name,
                type: image.type,
                images: [image]
              })
                  break
                case 'Plant Health': name = 'Plant Health'
				index = groups.findIndex(group => group.type === image.type)
              if (index >= 0) groups[index].images.push(image)
              else groups.push({
                name: name,
                type: image.type,
                images: [image]
              })

                  break
                case 'forest cover area': name = 'Forest Cover Area'
                  break
                default:
                    name = image.type.replaceAll('_', ' ')
              }
			  
          })
          break
        case 'month':
          this.allImage.forEach(image => {
            if (!groups.length) {
              groups.push({
                name: 'data of ' + image.month + '/' + + image.year,
                month: image.month,
                images:[image]
              })
            } else {
              let index = groups.findIndex(group => group.month === image.month)
              if (index >= 0) groups[index].images.push(image)
              else groups.push({
                name: 'data of ' + image.month + '/' + + image.year,
                month: image.month,
                images:[image]
              })
            }
          })
          break
      }
      groups.sort(function(a, b) {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      })
      groups.forEach(group => {
        group.images.sort(function(a, b) {
          if (a.name < b.name) return -1
          if (a.name > b.name) return 1
          return 0
        })
      })
      return groups
    },
  },
  watch: {
    currentDatasource (val) {
      this.getAOI()
      this.getData()
    },
    mapStatus (val) {
		  
      let timeLayer = []
      this.layers.forEach(layer => {
        if (layer.isMosaic || layer.isSlope) timeLayer.push(layer)
      })
      timeLayer.forEach(layer => {
        this.$refs.layerControl.removeLayer(layer)
      })
      switch (val) {
        case 'slide': this.$refs.mapView.changeCompare(true)
          break
        case 'sync': this.$refs.mapView.syncMap()
          break
        default:
          this.$refs.mapView.changeCompare(false)
          break
      }
    }
  },
  created() {
  },
  mounted() {
    
  localStorage.setItem('yymm_item','0')
    //this.getData()
    this.getAOI()
	
	window.mapView = this.$refs.mapView
	window.changeListLayer = this.changeListLayer
	
	setTimeout(this.resize_maps , 1000)
    // this.displayRefImage()
  },
  methods: {
  
	moa_to_imageries(moajs)
	{
    var c = 1
	  var imjs = []
	
  console.log('Imageries Res: ' ,moajs)
	moajs.forEach(i => {
          imjs.push(
		  {
		  id : 'imagery-' + String(c),
		  name : String(i.year) + '_' + i.month + '_monthly_image_mosaic',
		  tile_url : i.mosaic_image,
		  min_zoom: 13,
		max_zoom: 18,
		list_values: [],
		   type: "mosaic",
      month: i.month,
      year: i.year,
      data_type: "raster_image",
      aoi_id: i.aoi_id,
	  bbox: i.bbox,
		  }
		  )
		  
		  c = c + 1
		  
         imjs.push(
		  {
		  id : 'imagery-' + String(c),
		  name : String(i.year) + '_' + i.month + '_monthly_image_mosaic',
		  tile_url : i.plant_health_image,
		  min_zoom: 13,
		max_zoom: 18,
		list_values: [],
		   type: "plant_health",
      month: i.month,
      year: i.year,
      data_type: "raster_image",
      aoi_id: i.aoi_id,
	  bbox: i.bbox,
		  }
		  )
		  
		  c = c + 1		  
        })
	
	return imjs
	},
  	resize_maps() {
	window.map.resize()
	window.map2.resize()
	window.map3.resize()
	},
	showChangeDetection() {
	this.paneSize = this.paneSize_prev
	//this.isChgShow = true
	setTimeout(this.resize_maps , 500)
	},	
	hideChangeDetection() {
	//this.isChgShow = false
	this.paneSize_prev = this.paneSize
	this.paneSize = 90
	setTimeout(this.resize_maps , 500)
	},
	change_closed() {	
	setTimeout(this.resize_maps , 500)
	this.tabsShow = true
	window.changetable = !this.tabsShow
	this.isChg = !this.tabsShow
	},
	syncmap(){
	this.mapStatus =  'sync'
	},
    changeMapStatus(){
	setTimeout(this.syncmap , 300)
	//this.paneSize = 60
	setTimeout(this.resize_maps , 600)
      this.mapStatus2 =  'change';
	  this.tabsShow = false;
	  window.changetable = !this.tabsShow
	  this.isChg = !this.tabsShow
      this.isChgShow = true;     
      console.log(this.mapStatus2) 
      console.log(this.isChgShow)
    },
    addSlopeImage (image) {
      if (!this.layers.some(val => val.id === image.id)) this.changeListLayer(image)
    },
    changeMosaicImage (newImage, oldImage) {
      if (oldImage) {
        let idx = this.layers.findIndex(layer => layer.id === oldImage.id)
        if (idx >= 0) {
          this.layers.splice(idx, 0, newImage)
          this.$refs.mapView.addImageLayer(newImage, oldImage.id)
          this.$refs.layerControl.removeLayer(oldImage)
        }
      }
      else if (!this.layers.some(layer => layer.id === newImage.id)) {
        this.layers.unshift(newImage)
        this.$refs.mapView.addImageLayer(newImage)
      }
    },
    changeOpacityValue (layer, opacity) {
      if (this.$refs.timeSeries) this.$refs.timeSeries.updateOpacityValue(layer, opacity)
      this.$refs.mapView.updateOpacity(layer, opacity)
    },
    updateOpacity (layer) {
      this.$refs.layerControl.updateOpacity(layer)
    },
    async inspectImage (longitude, latitude) {
      if (this.mapStatus === 'time') await this.getDataTimeSeries(longitude, latitude)
    },
    async getDataTimeSeries (longitude, latitude) {
      try {
        this.loading = true
        const res = await inspectData({
          source: this.currentDatasource,
          year: new Date().getFullYear(),
          longitude: longitude,
          latitude: latitude
        })
        if (this.$refs.timeSeries) this.$refs.timeSeries.displayChart(res.data.data.slope, res.data.data)
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getAOI () {
      try {
        this.loading = true
        const res = await getAOI({source: this.currentDatasource})
        const data = res.data.data
        data.forEach(value => {
          value['id'] = `AOI-${value.id}`
          value['selected'] = false
          value['layoutRight'] = true
          value['opacity'] = 1
          value['layoutLeft'] = true
          value['displayLabel'] = false
          value['map_type'] = 'vector'
          value['color'] = randomColor.getColor()
          value['bbox'] = bbox(value.geometry)
        })
        this.aois = data
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
	        var aois_vector = {
      id : 'aois',
      // layout: { 'visibility': 'visible'},
      name : 'ALL AOI',
      type : 'aois',
      data_type: 'vector',
      selected: true,
      layoutRight: true,
      opacity: 1,
      layoutLeft: true,
      displayLabel: true,
      map_type: 'vector',
      color: '#26a69a',
      geometry : aois_geojson
      }
      this.vecLayer = aois_vector;
	    this.$refs.mapView.addVectorLayer(aois_vector)
      this.layers.push(aois_vector);
    },
    removeCurrentLayer(layer){
      //console.log('Before MapView: ', this.$refs.mapView);
      //console.log('Layers:', this.layers)
      this.$refs.mapView.removeLayer(layer)
      //console.log('Current Layer: ', this.$refs.mapView.layers)
      //console.log('MapView: ', this.$refs.mapView);
    },
    removeVectorLyr(){
      // console.log(this.$refs.mapView);
      this.$refs.mapView.removeLayer(this.vecLayer);
      console.log('Remove:', this.$refs.mapView)
      // console.log('Remove')
    },
    addVectorLyr(){
      this.getAOI();
    },
    changeGroupBy (val) {
      this.groupType = val
    },
    async changeListLayer (currentLayer) {
      console.log('From Child: ', currentLayer)
      if (!this.layers.some(layer => layer.id === currentLayer.id)) {
        this.layers.unshift(currentLayer)
        switch (currentLayer.map_type) {
          case 'raster_image': {
		  	  	   if (!currentLayer['AoiName'])
		    currentLayer['AoiName'] = this.aoi.name

            this.$refs.mapView.addImageLayer(currentLayer)
          }
            break
          case 'symbol': {
		  	  	   

            this.$refs.mapView.addSymbolLayer(currentLayer)
          }
            break			
          case 'vector':
            if (!currentLayer.geometry) {
              try {
                this.loading = true
                const res = await getGeom(currentLayer.id)
                currentLayer.geometry = res.data.data
                currentLayer.bbox = bbox(res.data.data)
              } catch (e) {
              } finally {
                this.loading = false
              }
            }
            this.$refs.mapView.addVectorLayer(currentLayer)
            // this.changeVector(currentLayer)
            break
        }
      }
    },
    changeAOI(aoi){
      console.log('From Child: ', aoi)
      this.aoi = aoi
		  this.aoi_id = aoi.name
      console.log('InIt AOI: ',this.aoi);
      this.getData()
    },	
    async getData () {
      this.allImage = []
      if (this.mapStatus === 'time') this.$refs.timeSeries.getImageData()
      else {
        try {
		
          this.loading = true
		  //Call Pham API for selected aoi and range [this.fromMonth, this.toMonth]
          let from = this.fromMonth.split('-')
          from = from[1] + '-' + from[0]
          let to = this.toMonth.split('-')
          to = to[1] + '-' + to[0]
          // console.log('AOI: ', this.aoi)
          let aoi = this.aoi.name
          aoi = aoi.split(' ')
          aoi = aoi[1]

          const res = await overviewAPI.getDetails(aoi,from, to)
      //     getImageries({
      //       dateRanger: [this.fromMonth, this.toMonth],
			// //dateRanger: ['', ''],
      //       source: this.currentDatasource
      //     })
		  
		  //Add bbox to the results

		  
		  var imageries = this.moa_to_imageries(res.data)
        let imageArr = []
        console.log()
         let data = imageries.filter(i => i.aoi_id == this.aoi.id)
          data.forEach(obj => {
            if(obj.type === 'mosaic'){
              let obj1 = {}
              obj1.name = obj.year.toString() + "_"+ obj.month + "_Satellite_Imagery_Mosaic"
              obj1.id = obj1.name
              obj1.AoiName = this.aoi.name
              obj1.aoi_id = obj1.name
              obj1.selected = true
              obj1.layoutRight = true
              obj1.opacity = 1
              obj1.layoutLeft = true
              obj1.displayLabel = true
              obj1.map_type = "raster_image"
              obj1.data_type = "raster_image"
              obj1.color = randomColor.getColor()
              obj1.tile_url = obj.tile_url
              obj1.min_zoom = 3,
              obj1.max_zoom = 22
              obj1.type = "Satellite_Imagery_Mosaic"
              obj1.bbox = this.aoi.bbox,
              imageArr.push(obj1)
            }

            if(obj.type === 'plant_health'){
              let obj2 = {}
              obj2.name = obj.year.toString() + "_"+ obj.month + "_Plant_Health"
              obj2.AoiName = this.aoi.name
              obj2.id = obj2.name
              obj2.aoi_id = obj2.name
              obj2.selected = true
              obj2.layoutRight = true
              obj2.opacity = 1
              obj2.layoutLeft = true
              obj2.displayLabel = true
              obj2.map_type = "raster_image"
              obj2.data_type = "raster_image"
              obj2.color = randomColor.getColor()
              obj2.min_zoom = 3,
              obj2.max_zoom = 22
              obj2.tile_url = obj.tile_url
              obj2.type = "Plant_Health"
              obj2.bbox = this.aoi.bbox,
          //                 if (image.list_values) image.list_values.forEach(value => {
          //     value['display'] = true
          // })
              imageArr.push(obj2)
            }

          })
          // console.log('Temp Arr: ', imageArr);
          this.allImage = imageArr
          // console.log('All OBJs: ', this.allImage)
          // console.log(this.allImage);
          // this.$refs.mapView.resetMap()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },
    changeMonth: debounce(function (month) {
      // this.fromMonth = fromMonth
      // this.toMonth = toMonth
      this.fromMonth = month.from
      this.toMonth = month.to
      this.getData()
    }, 500),
    changeViewImageLeftMap (image) {
      image.layoutLeft = !image.layoutLeft
      this.$refs.mapView.changeViewImage(image, 'left')
    },
    changeViewImageRightMap (image) {
      image.layoutRight = !image.layoutRight
      this.$refs.mapView.changeViewImage(image, 'right')
    },
    changeVector (vector) {
      vector.layoutRight = !vector.layoutRight
      if (vector.layoutRight) {
        vector.layoutLeft = true
        this.$refs.mapView.addVectorLayer(vector)
      } else {
        vector.layoutLeft = false
        this.$refs.mapView.removeLayer(vector)
      }
    },
    async newMoveLayer (listImage) {
      this.$refs.mapView.removeAllLayer()
		this.$refs.mapView.layers = listImage;
      for (let i = listImage.length - 1; i >= 0; i--) {
        switch (listImage[i].map_type) {
          case 'raster_image': this.$refs.mapView.addImageLayer(listImage[i])
            break
          case 'vector': {
            if (!listImage[i].geometry) {
              try {
                const res = await getGeom(listImage[i].id)
                listImage[i].geometry = res.data.data
              } catch (e) {
              }
            }
            this.$refs.mapView.addVectorLayer(listImage[i])
          }
            break
        }
      }
    },
    changeViewImage (image) {
      image.layoutRight = !image.layoutRight
      if (image.layoutRight) {
        image.layoutLeft = true
        this.$refs.mapView.addImageLayer(image)
      } else {
        image.layoutLeft = false
        this.$refs.mapView.removeLayer(image)
      }
    },
    zoomToImage (image) {
      if(image.type==="AOI"){
        //To handle props data passing fast, not enough time
        this.currentAOI = image.name
        // if(!this.propsAOI){this.propsAOI = image.name}
        // if(this.propsAOI != this.currentAOI){ this.currentAOI = undefined }
      
      }
      if (image.data_type==="raster_image"){
            image.AoiName = this.currentAOI;
            console.log('RasterType', image)
        };
      console.log('zoomToImage --> FROM AOI Selection', image);
      this.$refs.mapView.submitZoom(image.bbox)
    }
  }
}
</script>
<style scoped>


/deep/
.select-month .v-select__slot {
  border-bottom: 1px #893FF2 solid;
}
/deep/
.select-month .v-select__selections {
  font-weight: bold;
}
/deep/
.v-chip__content {
  width: 180px !important;
}
/deep/
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: inset 0px 4px 6px -5px rgb(50 50 50 / 75%),
  inset 0 -4px 8px -5px rgb(50 50 50 / 0%);
}
/deep/
.order-table th {
  background-color: #893FF2 !important;
  color: white !important;
}
/deep/
.list-image th {
  background-color: #6686a3 !important;
}
</style>
<style>
.monthRanger .v-slider__tick{
  border-left: 0.5px solid !important;
  height: 10px !important;
  position: absolute !important;
  top: -5px !important;
  width: 1px !important;
}
.monthRanger .v-slider__tick-label {
  padding-top: 20px;
  font-size: 11px !important;
}
.VerticalLeftTab .v-tabs-bar {
  height: 350px !important;
}
.verticalTabColumn{
  width: 60px;
  background-color: rgba(137, 63, 242, 0.22);
  padding-right: 0px;
}
.verticalTabColumn a.v-tab:hover{
  background-color: white !important;
}

.VerticalLeftTab div div.v-slide-group__wrapper a.v-tab--active{
  border-top: 3px solid #893FF2;
  border-bottom: 3px solid #893FF2;
}

.MiniVerticalLeftTab .v-tabs-bar {
  height: 350px !important;
}

.MiniVerticalLeftTab div div.v-slide-group__wrapper a.v-tab--active{
  border-top: none;
  border-bottom: none;
}

.panelContainer{
  border-radius: 15px 15px  0px 0px;             
}

.libraryHeader{ 
  font-weight: bold;
  font-size: 25.5px;
  line-height: 30px;
  letter-spacing: 0.1em;
  color: #8539F0;
}

.hideLeftBtn{
  background: #DFCEF8;
  border: 1px solid #893FF2;
  box-sizing: border-box;
  border-radius: 0px 7px;
  color: #8539F0 !important;
  transform: translate(17px, -3px);
}
</style>
